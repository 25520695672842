import React from "react";

export const SpotifyLogo = () => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="listen-link-logo"
  >
    <path
      d="M30.5035 16.7423C22.9307 12.6513 13.7145 13.6263 10.053 14.6251C9.493 14.7445 8.46348 15.2831 8.82532 16.4817C9.18716 17.6803 10.2468 17.6543 10.7314 17.4914C18.4852 15.6153 25.4851 17.5783 28.0158 18.7943C28.7373 19.3588 30.3419 20.1949 30.9881 19.0223C31.6342 17.8497 30.9342 17.0137 30.5035 16.7423Z"
      fill="currentColor"
    />
    <path
      d="M28.4358 22.1817C21.3799 18.1168 13.8653 19.0548 10.9899 20.032C10.3653 20.1297 9.56841 20.7486 9.82687 21.6606C10.113 22.6703 11.2268 22.5834 11.5715 22.3771C17.6452 20.501 23.9667 22.5726 26.3682 23.8429C27.0035 24.2989 28.4358 24.8761 28.8558 24.0057C29.2758 23.1353 28.7697 22.388 28.4358 22.1817Z"
      fill="currentColor"
    />
    <path
      d="M26.3682 26.7417C21.1732 23.5367 14.2099 24.2772 11.3776 25.048C10.9361 25.1674 10.1499 25.6017 10.5376 26.3834C10.9253 27.1652 11.5822 27.1434 11.8622 27.0349C17.8326 25.5236 22.9651 27.1 24.7851 28.0772C25.259 28.5115 26.3423 29.1781 26.8851 28.3703C27.4279 27.5625 26.7666 26.948 26.3682 26.7417Z"
      fill="currentColor"
    />
  </svg>
);

export const AppleLogo = () => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="listen-link-logo"
  >
    <path
      d="M20.8113 24.2641C21.3805 23.4513 23.2553 23.0688 24.1216 22.9792C24.8889 22.8537 25.06 22.3691 25.0496 22.1425V15.3594C25.0496 14.8574 24.5753 14.8514 24.3381 14.9112L16.4799 16.4351C15.9354 16.459 15.8818 17.0826 15.923 17.3913V27.1626C15.7993 28.0292 15.428 29.3738 13.5408 29.8519C11.6536 30.33 10.973 29.5232 10.6018 29.2244C10.2305 28.9256 9.73551 27.8798 10.1686 26.7741C10.5151 25.8896 11.6743 25.4295 12.2105 25.3099C12.6333 25.2303 13.6089 25.041 14.1287 24.9215C14.6484 24.802 14.7577 24.2342 14.7474 23.9653V12.8195C14.8217 12.174 15.3559 11.9529 15.6137 11.923L25.3281 10.0106C26.0954 9.915 26.246 10.4887 26.2253 10.7875V14.6721C26.1841 17.4411 26.1263 23.2362 26.2253 24.2641C26.3243 25.292 26.1016 26.0271 25.9778 26.2661C25.2044 27.7303 24.1216 27.7901 23.0078 27.9694C21.894 28.1487 20.9659 27.3718 20.5947 26.7741C20.2234 26.1765 20.0997 25.2801 20.8113 24.2641Z"
      fill="currentColor"
    />
  </svg>
);

export const YoutubeLogo = () => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="listen-link-logo"
  >
    <path
      d="M16.9336 13.1837L16.9336 26.8163L29.4336 19.551L16.9336 13.1837Z"
      fill="currentColor"
    />
  </svg>
);

export const AmazonLogo = () => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="listen-link-logo"
  >
    <path
      d="M6.0684 16.6666C5.91134 16.8513 6.06113 17.1027 6.15566 17.2053C9.15732 20.4477 13.1654 21.9766 14.7942 22.3357C16.314 22.7034 20.2086 23.1617 23.6291 22.0535C27.0496 20.9454 28.8791 19.6081 29.3663 19.0779C29.4754 18.9155 29.6543 18.5495 29.4972 18.3853C29.3402 18.2211 29.01 18.3169 28.8646 18.3853L27.054 19.1805C22.7783 20.7453 19.8552 20.8992 15.4923 20.4118C12.002 20.0219 8.14808 17.8894 6.65742 16.8718C6.52652 16.7265 6.22547 16.4819 6.0684 16.6666Z"
      fill="currentColor"
    />
    <path
      d="M31.526 16.2562C29.8157 15.5995 27.992 16.3588 27.294 16.8205C27.1994 16.8975 27.0104 17.0873 27.0104 17.231C27.0104 17.3746 27.1122 17.3934 27.1631 17.3849C28.0356 17.1027 29.6281 17.231 30.1953 17.2566C30.649 17.2771 30.7188 17.6414 30.697 17.821C30.4789 19.9501 29.9553 20.8479 29.8463 21.2583C29.759 21.5867 29.9408 21.6175 30.0426 21.5918C31.8096 19.9501 31.875 17.2634 31.875 16.8205C31.875 16.4871 31.6641 16.299 31.526 16.2562Z"
      fill="currentColor"
    />
  </svg>
);

export const DeezerLogo = () => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="listen-link-logo"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.874 13.75H30.895V14.9738H26.874V13.75ZM17.8412 15.3234H21.8622V16.5472H17.8412V15.3234ZM17.8412 16.8969H21.8622V18.1207H17.8412V16.8969ZM12.771 18.4703H8.75V19.6941H12.771V18.4703ZM21.8622 18.4703H17.8412V19.6941H21.8622V18.4703ZM22.3284 18.4703H26.3494V19.6941H22.3284V18.4703ZM30.895 18.4703H26.874V19.6941H30.895V18.4703ZM30.895 16.8969H26.874V18.1207H30.895V16.8969ZM30.895 15.3234H26.874V16.5472H30.895V15.3234ZM12.771 20.0437H8.75V21.2675H12.771V20.0437ZM17.8412 20.0437H21.8622V21.2675H17.8412V20.0437ZM26.3494 20.0437H22.3284V21.2675H26.3494V20.0437ZM26.874 20.0437H30.895V21.2675H26.874V20.0437ZM12.771 21.6172H8.75V22.841H12.771V21.6172ZM17.8412 21.6172H21.8622V22.841H17.8412V21.6172ZM26.3494 21.6172H22.3284V22.841H26.3494V21.6172ZM26.874 21.6172H30.895V22.841H26.874V21.6172ZM17.3166 21.6172H13.2956V22.841H17.3166V21.6172ZM8.75 23.1906H12.771V24.4144H8.75V23.1906ZM21.8622 23.1906H17.8412V24.4144H21.8622V23.1906ZM22.3284 23.1906H26.3494V24.4144H22.3284V23.1906ZM30.895 23.1906H26.874V24.4144H30.895V23.1906ZM13.2956 23.1906H17.3166V24.4144H13.2956V23.1906ZM12.771 24.7641H8.75V25.9879H12.771V24.7641ZM17.8412 24.7641H21.8622V25.9879H17.8412V24.7641ZM26.3494 24.7641H22.3284V25.9879H26.3494V24.7641ZM26.874 24.7641H30.895V25.9879H26.874V24.7641ZM17.3166 24.7641H13.2956V25.9879H17.3166V24.7641Z"
      fill="currentColor"
    />
  </svg>
);

export const BandcampLogo = () => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="listen-link-logo"
  >
    <path d="M16.3673 11H30L23.6327 21H10L16.3673 11Z" fill="currentColor" />
  </svg>
);
